import { CatalogsListFilterField } from 'entries/catalogs';
import { CatalogsState } from './Models';
import { createReducer } from '@reduxjs/toolkit';
import {
  clearCatalogsFilters,
  fetchCatalogs,
  setCatalogSearch,
} from './catalogList/actions';
import { fetchCatalogProducts } from './catalogProducts/actions';
import { fetchCatalogInfoById } from './catalogInfo/action';

export const initialState: CatalogsState = {
  filter: {
    [CatalogsListFilterField.CatalogId]: '',
    [CatalogsListFilterField.SearchText]: '',
    [CatalogsListFilterField.Country]: '',
    [CatalogsListFilterField.Currency]: '',
    [CatalogsListFilterField.CategoryId]: '',
  },
  catalogs: {
    data: {
      pageSize: 0,
      pageIndex: 0,
      count: 0,
      items: [],
    },
    isLoading: true,
    error: null,
  },
  catalogProducts: {
    data: [],
    isLoading: true,
    error: null,
  },
  catalogInfo: {
    data: null,
    isLoading: true,
    error: null,
  },
};

export const catalogs = createReducer(initialState, (builder) => {
  builder
    .addCase(setCatalogSearch, (state, action) => {
      state.filter = { ...state.filter, ...action.payload };
    })
    .addCase(clearCatalogsFilters, (state) => {
      state.filter = initialState.filter;
    })
    .addCase(fetchCatalogs.pending, (state) => {
      state.catalogs.isLoading = true;
      state.catalogs.error = null;
    })
    .addCase(fetchCatalogs.fulfilled, (state, action) => {
      state.catalogs.isLoading = false;
      state.catalogs.data = action.payload;
    })
    .addCase(fetchCatalogs.rejected, (state, action) => {
      state.catalogs.isLoading = false;
      state.catalogs.error = action.payload || 500;
    })
    .addCase(fetchCatalogProducts.pending, (state) => {
      state.catalogProducts.isLoading = true;
      state.catalogProducts.error = null;
    })
    .addCase(fetchCatalogProducts.fulfilled, (state, action) => {
      state.catalogProducts.isLoading = false;
      state.catalogProducts.data = action.payload;
    })
    .addCase(fetchCatalogProducts.rejected, (state, action) => {
      state.catalogProducts.isLoading = false;
      state.catalogProducts.error = action.payload || 500;
    })
    .addCase(fetchCatalogInfoById.pending, (state) => {
      state.catalogInfo.isLoading = true;
      state.catalogInfo.error = null;
    })
    .addCase(fetchCatalogInfoById.fulfilled, (state, action) => {
      state.catalogInfo.isLoading = false;
      state.catalogInfo.data = action.payload;
    })
    .addCase(fetchCatalogInfoById.rejected, (state, action) => {
      state.catalogInfo.isLoading = false;
      state.catalogInfo.error = action.payload || 500;
    });
});
