export enum NewClientField {
  CompanyName = 'name',
  CommercialStructure = 'commercialStructure',
  SettlementMethod = 'settlementMethod',
  AccountType = 'accountType',
  Integration = 'integrationType',
  BusinessName = 'businessContact.name',
  BusinessEmail = 'businessContact.email',
  BusinessPhone = 'businessContact.number',
  ItName = 'it.name',
  ItEmail = 'it.email',
  ItPhone = 'it.number',
  FinanceName = 'finance.name',
  FinanceEmail = 'finance.email',
  FinancePhone = 'finance.number',
  SupportName = 'support.name',
  SupportEmail = 'support.email',
  SupportPhone = 'support.number',
  ClientCommission = 'exchangeCommission',
  CompanyLogo = 'companyLogo',
  InvoiceFrequency = 'invoiceFrequency',
}

export interface ClientInputs {
  [NewClientField.CompanyName]: string;
  [NewClientField.CommercialStructure]: string | null;
  [NewClientField.SettlementMethod]: string | null;
  [NewClientField.AccountType]: string | null;
  [NewClientField.Integration]: string | null;
  [NewClientField.BusinessName]: string;
  [NewClientField.BusinessEmail]: string;
  [NewClientField.BusinessPhone]: string;
  [NewClientField.ItName]: string;
  [NewClientField.ItEmail]: string;
  [NewClientField.ItPhone]: string;
  [NewClientField.FinanceName]: string;
  [NewClientField.FinanceEmail]: string;
  [NewClientField.FinancePhone]: string;
  [NewClientField.SupportName]: string;
  [NewClientField.SupportEmail]: string;
  [NewClientField.SupportPhone]: string;
  [NewClientField.ClientCommission]: string;
  [NewClientField.CompanyLogo]: string | string[] | null;
  [NewClientField.InvoiceFrequency]: string;
}
