import { State } from './Models';
import { createReducer } from '@reduxjs/toolkit';
import {
  setResetPasswordEmailAndCode,
  successfulResetPasswordRequest,
} from 'store/resetPassword/actions';

export const initialState: State = {
  email: '',
  code: '',
};

export const resetPassword = createReducer(initialState, (builder) => {
  builder
    .addCase(setResetPasswordEmailAndCode, (state, action) => {
      state.email = action.payload.email;
      state.code = action.payload.code;
    })
    .addCase(successfulResetPasswordRequest, (state) => {
      state.code = '';
      state.email = '';
    });
});
