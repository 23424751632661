export enum TopUpAccount {
  Amount = 'amount',
  Comment = 'comment',
  ReferenceNumber = 'referenceNumber',
}

export interface AccountTopUpInputs {
  [TopUpAccount.Amount]: number;
  [TopUpAccount.Comment]: string;
  [TopUpAccount.ReferenceNumber]: string;
}

export enum TopDownAccount {
  Amount = 'amount',
  Comment = 'comment',
}

export interface AccountTopDownInputs {
  [TopUpAccount.Amount]: number;
  [TopUpAccount.Comment]: string;
}
