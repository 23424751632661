import { makeStyles } from '@material-ui/core/styles';

export const useClasses = makeStyles({
  body: {
    background: '#5F3D8F',
    width: '100vw',
    height: '100vh',
  },
  logo: {
    display: 'flex',
    padding: '2% 3%',
  },
  logoLink: {
    height: '23px',
  },
  mainContent: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingLeft: '3%',
  },
  vectorPng: {
    position: 'relative',
    height: '80vh',
  },
  mainImg: {
    position: 'absolute',
    width: 'auto',
    height: '75vh',
    top: '13%',
    right: '0%',
  },
  text: {
    color: '#fff',
    fontWeight: 500,
    fontSize: 36,
    display: 'flex',
    marginTop: '10%',
  },
  img: {},
});
