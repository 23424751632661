import { parseJwt } from 'utils/parseJwt';
import { ROLES } from 'config/RBAC/userRoles';
import { TokenRepository } from './tokenRepositoryInterface';

const accessTokenKey = 'accessToken';
const identityToken = 'identityToken';
const refreshTokenKey = 'refreshToken';

export const createTokenRepository = (): TokenRepository => {
  return {
    getAccessToken() {
      return localStorage.getItem(accessTokenKey);
    },

    setAccessToken(token: string) {
      localStorage.setItem(accessTokenKey, token);
    },

    removeToken() {
      localStorage.removeItem(accessTokenKey);
    },

    getIdentityToken() {
      return localStorage.getItem(identityToken);
    },

    setIdentityToken(idToken: string) {
      return localStorage.setItem(identityToken, idToken);
    },

    removeIdentityToken() {
      return localStorage.removeItem(identityToken);
    },

    getRefreshToken() {
      return localStorage.getItem(refreshTokenKey);
    },

    setRefreshToken(refreshToken: string) {
      localStorage.setItem(refreshTokenKey, refreshToken);
    },

    removeRefreshToken() {
      localStorage.removeItem(refreshTokenKey);
    },

    getUserRole() {
      const token = localStorage.getItem(accessTokenKey);

      if (!token) {
        return ROLES.USER;
      }

      const userRole = () => {
        return ROLES.USER;
      };

      return userRole();
    },
  };
};
