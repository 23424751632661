import { USER_PERMISSIONS } from 'config/RBAC';
import { checkPermissions } from 'utils/checkPermissions';
import { tokenRepository } from 'config/auth';

export function usePermissionAccess(permission: USER_PERMISSIONS) {
  const userRole = tokenRepository.getUserRole();

  if (permission) {
    if (userRole) {
      return checkPermissions(userRole, permission);
    }
  }

  return false;
}
