import { ENDPOINTS } from 'api/endpoints';
import { apiClient } from 'config/auth';
import { MyCompanyDetailsResponse } from './Models';

export const getCompanyDetails = async (): Promise<
  MyCompanyDetailsResponse
> => {
  const response = await apiClient.get(`${ENDPOINTS.PROFILE}/company-details`);
  return response.data;
};
