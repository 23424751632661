import { createAsyncThunk } from '@reduxjs/toolkit';
import { getCatalogProducts } from 'api/catalogs/getCatalogProducts';
import { Arguments, Products } from 'api/catalogs/getCatalogProducts/Models';

type AsyncThunkOptions = {
  rejectValue: number;
};

export const fetchCatalogProducts = createAsyncThunk<
  Products[],
  { catalogId: string } & { params: Arguments },
  AsyncThunkOptions
>(
  'catalogs/fetchCatalogProducts',
  async ({ catalogId, params }, { rejectWithValue }) => {
    try {
      return await getCatalogProducts(catalogId, params);
    } catch (error) {
      return rejectWithValue(error.response.status);
    }
  },
);
