import { apiClient } from 'config/auth';
import { ENDPOINTS } from 'api/endpoints';
import { Arguments } from './Models';

export const getOrCreateShoppingCart = async (
  body: Arguments,
): Promise<number> => {
  const response = await apiClient.post(ENDPOINTS.SHOPPING_CART_LIST, body);

  return response.data;
};
