import { apiClient } from 'config/auth';
import { ENDPOINTS } from 'api/endpoints';
import { Arguments, OrderHistoryClientModel } from './Models';

export const getOrderHistoryClient = async (
  params: Arguments,
): Promise<OrderHistoryClientModel> => {
  const response = await apiClient.get(
    `${ENDPOINTS.CLIENT_PORTAL_ORDERS}/orders`,
    {
      params,
    },
  );

  return response.data;
};
