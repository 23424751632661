import { State } from './Models';
import { createReducer } from '@reduxjs/toolkit';
import {
  setFilters,
  updateBrandsFilter,
  updateNonIntegratedBrandsFilter,
  updateSuppliersFilter,
  updateNonIntegratedSuppliersFilter,
  updateClientsFilter,
} from 'store/filters/actions';

export const initialState: State = {
  filters: {
    paymentMethods: [],
    currencies: [],
    accountCurrencies: [],
    countries: [],
    suppliers: [],
    integratedSuppliers: [],
    nonIntegratedSuppliers: [],
    statuses: [],
    accountType: [],
    brands: [],
    integratedBrands: [],
    nonIntegratedBrands: [],
    clients: [],
    discountTypes: [],
    integrationType: [],
    commercialStructures: [],
    userRoles: [],
  },
  clientFilters: {
    brands: [],
    statuses: [],
    currencies: [],
    accountCurrencies: [],
    countries: [],
  },
  error: null,
  isFetched: false,
};

export const filter = createReducer(initialState, (builder) => {
  builder
    .addCase(setFilters.fulfilled, (state, action) => {
      state.isFetched = true;
      state.error = null;
      state.filters = action.payload || initialState.filters;
    })
    .addCase(updateSuppliersFilter.fulfilled, (state, action) => {
      state.filters.suppliers =
        action.payload || initialState.filters.suppliers;
    })
    .addCase(updateNonIntegratedSuppliersFilter.fulfilled, (state, action) => {
      state.filters.nonIntegratedSuppliers =
        action.payload || initialState.filters.nonIntegratedSuppliers;
    })
    .addCase(updateBrandsFilter.fulfilled, (state, action) => {
      state.filters.brands = action.payload || initialState.filters.brands;
    })
    .addCase(updateNonIntegratedBrandsFilter.fulfilled, (state, action) => {
      state.filters.nonIntegratedBrands =
        action.payload || initialState.filters.nonIntegratedBrands;
    })
    .addCase(updateClientsFilter.fulfilled, (state, action) => {
      state.filters.clients = action.payload || initialState.filters.clients;
    });
});
