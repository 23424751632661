import { createAsyncThunk } from '@reduxjs/toolkit';
import { getCompanyUsers } from 'api/myCompany/getCompanyUsers';
import {
  Arguments,
  CompanyUsersResponse,
} from 'api/myCompany/getCompanyUsers/Models';

type AsyncThunkOptions = {
  rejectValue: number;
};

export const fetchCompanyUsers = createAsyncThunk<
  CompanyUsersResponse,
  Arguments,
  AsyncThunkOptions
>('orders/fetchCompanyUsers', async (queryParams, { rejectWithValue }) => {
  try {
    return await getCompanyUsers(queryParams);
  } catch (error) {
    return rejectWithValue(error.response.status);
  }
});
