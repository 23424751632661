import { OrderDetailState } from './Models';
import { createReducer } from '@reduxjs/toolkit';
import { fetchOrderItems } from './orderAllItems/action';
import { fetchOrderInfoById } from './orderInfo/action';

export const initialState: OrderDetailState = {
  order: {
    orderInfo: null,
    isLoading: false,
    error: null,
    orderItems: {
      items: {
        pageSize: 0,
        pageIndex: 0,
        count: 0,
        items: [],
      },
      isLoading: true,
      error: null,
    },
  },
};

export const order = createReducer(initialState, (builder) => {
  builder
    .addCase(fetchOrderInfoById.pending, (state) => {
      state.order.isLoading = true;
      state.order.error = null;
    })
    .addCase(fetchOrderInfoById.fulfilled, (state, action) => {
      state.order.isLoading = false;
      state.order.orderInfo = action.payload;
    })
    .addCase(fetchOrderInfoById.rejected, (state, action) => {
      state.order.isLoading = false;
      state.order.error = action.payload || 500;
    })
    .addCase(fetchOrderItems.pending, (state) => {
      state.order.orderItems.isLoading = true;
      state.order.orderItems.error = null;
    })
    .addCase(fetchOrderItems.fulfilled, (state, action) => {
      state.order.orderItems.isLoading = false;
      state.order.orderItems.items = action.payload;
    })
    .addCase(fetchOrderItems.rejected, (state, action) => {
      state.order.orderItems.isLoading = false;
      state.order.orderItems.error = action.payload || 500;
    });
});
