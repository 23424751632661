import { apiClient } from 'config/auth';
import { ENDPOINTS } from 'api/endpoints';
import { OrderItemResponse } from './Models';

type Params = {
  pageSize: number;
  pageIndex: number;
};

export const getOrderItems = async (
  orderId: string,
  params: Params,
): Promise<OrderItemResponse> => {
  const response = await apiClient.get(
    `${ENDPOINTS.ORDERS}/order-details/${orderId}`,
    {
      params,
    },
  );

  return response.data;
};
