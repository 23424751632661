import { createReducer } from '@reduxjs/toolkit';
import { MyCompanyState } from './Models';
import { fetchCompanyDetails } from './companyDetails/action';
import { fetchCompanyUsers } from './companyUsers/action';

export const initialState: MyCompanyState = {
  details: {
    data: null,
    isLoading: false,
    error: null,
  },
  users: {
    data: {
      pageSize: 0,
      pageIndex: 0,
      count: 0,
      items: [],
    },
    isLoading: true,
    error: null,
  },
};

export const myCompany = createReducer(initialState, (builder) => {
  builder
    .addCase(fetchCompanyDetails.pending, (state) => {
      state.details.isLoading = true;
      state.details.error = null;
    })
    .addCase(fetchCompanyDetails.fulfilled, (state, action) => {
      state.details.isLoading = false;
      state.details.data = action.payload;
    })
    .addCase(fetchCompanyDetails.rejected, (state, action) => {
      state.details.isLoading = false;
      state.details.error = action.payload || 500;
    })
    .addCase(fetchCompanyUsers.pending, (state) => {
      state.users.isLoading = true;
      state.users.error = null;
    })
    .addCase(fetchCompanyUsers.fulfilled, (state, action) => {
      state.users.isLoading = false;
      state.users.data = action.payload;
    })
    .addCase(fetchCompanyUsers.rejected, (state, action) => {
      state.users.isLoading = false;
      state.users.error = action.payload || 500;
    });
});
