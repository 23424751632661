import { RULES, USER_PERMISSIONS, USER_ROLES } from 'config/RBAC';

export const checkPermissions = (
  role: USER_ROLES,
  action: USER_PERMISSIONS,
): boolean => {
  if (!action) {
    return false;
  }
  const STATIC_PERMISSIONS = RULES[role];

  return STATIC_PERMISSIONS && STATIC_PERMISSIONS.static.includes(action);
};
