import { apiClient } from 'config/auth';
import { ENDPOINTS } from 'api/endpoints';
import { Options } from 'entries/filters';

type Response = {
  data: Options;
};

export const getUserRoles = async (): Promise<Options> => {
  const response: Response = await apiClient.get(ENDPOINTS.USER_ROLES);

  const stringUserRoles = response.data.map((item) => ({
    ...item,
    value: item.name.toString(),
  }));

  return stringUserRoles;
};
