import { apiClient } from 'config/auth';
import { ENDPOINTS } from 'api/endpoints';
import { Response, Arguments } from './Models';

export const getCatalogs = async (params: Arguments): Promise<Response> => {
  const response = await apiClient.get(`${ENDPOINTS.CLIENT_CATALOGS}/brands`, {
    params,
  });

  return response.data;
};
