import { createAsyncThunk } from '@reduxjs/toolkit';
import { CatalogDetailsResponse } from 'api/catalogs/getCatalogInfoById/Models';
import { getCatalogInfoById } from 'api/catalogs/getCatalogInfoById';

type Params = {
  targetCurrency: string;
};
type AsyncThunkOptions = {
  rejectValue: number;
};

export const fetchCatalogInfoById = createAsyncThunk<
  CatalogDetailsResponse,
  { id: number } & { params: Params },
  AsyncThunkOptions
>(
  'orders/fetchCatalogInfoById',
  async ({ id, params }, { rejectWithValue }) => {
    try {
      return await getCatalogInfoById(id, params);
    } catch (error) {
      return rejectWithValue(error.response.status);
    }
  },
);
