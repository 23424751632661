import { createReducer } from '@reduxjs/toolkit';
import { NotificationState } from './Models';
import { fetchNotifications } from './action';

export const initialState: NotificationState = {
  notifications: {
    pageSize: 0,
    pageIndex: 0,
    count: 0,
    items: [],
  },
  isLoading: false,
  error: null,
};

export const notifications = createReducer(initialState, (builder) => {
  builder
    .addCase(fetchNotifications.pending, (state) => {
      state.isLoading = true;
      state.error = null;
    })
    .addCase(fetchNotifications.fulfilled, (state, action) => {
      state.isLoading = false;
      state.notifications = action.payload;
    })
    .addCase(fetchNotifications.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload || 500;
    });
});
