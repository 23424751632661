import { apiClient } from 'config/auth';
import { Arguments, Products } from './Models';
import { ENDPOINTS } from 'api/endpoints';

export const getCatalogProducts = async (
  catalogId: string | number,
  params: Arguments,
): Promise<Products[]> => {
  const response = await apiClient.get(
    `${ENDPOINTS.CLIENT_CATALOGS}/${catalogId}/brands/products`,
    {
      params,
    },
  );

  return response.data;
};
