import { apiClient } from 'config/auth';
import { ENDPOINTS } from 'api/endpoints';
import { Arguments, ShoppingCartResponse } from './Models';

export const getShoppingCartByCurrency = async (
  params: Arguments,
): Promise<ShoppingCartResponse> => {
  const response = await apiClient.get(ENDPOINTS.SHOPPING_CART, {
    params,
  });

  return response.data;
};
