import { createAsyncThunk } from '@reduxjs/toolkit';
import { getNotifications } from 'api/notifications/getNotifications';

import {
  Arguments,
  NotificationResponse,
} from 'api/notifications/getNotifications/Models';

type AsyncThunkOptions = {
  rejectValue: number;
};

export const fetchNotifications = createAsyncThunk<
  NotificationResponse,
  Arguments,
  AsyncThunkOptions
>('orders/fetchNotifications', async (queryParams, { rejectWithValue }) => {
  try {
    return await getNotifications(queryParams);
  } catch (error) {
    return rejectWithValue(error.response.status);
  }
});
