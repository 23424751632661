export enum NewCatalogField {
  CatalogName = 'name',
  CatalogImage = 'image',
  Description = 'description',
  ClientDiscountType = 'clientDiscountType',
  ClientDiscountValue = 'clientDiscountValue',
  TransactionFeeType = 'transactionFeeType',
  TransactionFeeValue = 'transactionFeeValue',
  ClientDiscountTypeSwitch = 'clientDiscountTypeSwitch',
  TransactionFeeTypeSwitch = 'transactionFeeTypeSwitch',
  SupplierPriceFee = 'supplierPriceFeeValue',
}

export interface CatalogInputs {
  [NewCatalogField.CatalogName]: string;
  [NewCatalogField.Description]: string;
  [NewCatalogField.ClientDiscountType]: string | null | undefined;
  [NewCatalogField.ClientDiscountValue]: number;
  [NewCatalogField.TransactionFeeType]: string | null | undefined;
  [NewCatalogField.TransactionFeeValue]: number;
  [NewCatalogField.CatalogImage]: File[] | null;
  [NewCatalogField.ClientDiscountTypeSwitch]: boolean;
  [NewCatalogField.TransactionFeeTypeSwitch]: boolean;
  [NewCatalogField.SupplierPriceFee]: string | number | null;
}
