import { apiClient } from 'config/auth';
import { ENDPOINTS } from 'api/endpoints';
import { Options } from 'entries/filters';
import { Params } from './Models';

export const getFilterBrands = async (
  params?: Partial<Params>,
): Promise<Options> => {
  const response = await apiClient.get(ENDPOINTS.FILTER_BRANDS, { params });

  return response.data;
};
