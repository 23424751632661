import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { LastLocationProvider } from 'react-router-last-location';

import AppContainer from 'modules/ui/molecules/AppContainer';
import { store } from './store';

const App: React.FC = () => {
  return (
    <Provider store={store}>
      <Router>
        <LastLocationProvider>
          <AppContainer />
        </LastLocationProvider>
      </Router>
    </Provider>
  );
};

export default App;
