import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Route, RouteProps, Redirect, generatePath } from 'react-router-dom';

import { setOriginRoutePath } from 'store/reactRouterHistory/actions';
import { usePermissionAccess } from 'hooks/usePermissionAccess';
import PageNotFound from 'modules/PageNotFound';
import { USER_PERMISSIONS } from 'config/RBAC';
import { tokenRepository } from 'config/auth';
import { routes } from 'routes/routes';

type OwnProps = {
  access: 'any' | 'authorized' | 'unauthorized';
  component: NonNullable<RouteProps['component']>;
  permission: USER_PERMISSIONS;
  isOpen?: boolean;
};

type Props = OwnProps & RouteProps;

export const PrivateRoute: React.FC<Props> = ({
  path,
  access = 'any',
  permission,
  component: Component,
  ...rest
}) => {
  const dispatch = useDispatch();
  const isAuthorized = tokenRepository.getAccessToken() !== null;
  const isPermissionAllowed = usePermissionAccess(permission);

  useEffect(() => {
    dispatch(setOriginRoutePath(path as string));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [path]);

  return (
    <Route
      {...rest}
      render={(props) => {
        if (access === 'any') {
          return <Component {...props} />;
        }

        if (access === 'unauthorized') {
          if (isAuthorized) {
            return (
              <Redirect
                to={{
                  pathname: generatePath(routes.index),
                  state: { from: props.location },
                }}
              />
            );
          }
          return <Component {...props} />;
        }

        if (access === 'authorized') {
          if (isAuthorized) {
            return isPermissionAllowed ? (
              <Component {...props} />
            ) : (
              <PageNotFound />
            );
          }
          return (
            <Redirect
              to={{
                pathname: generatePath(routes.signInLanding),
                state: { from: props.location },
              }}
            />
          );
        }

        return <Component {...props} />;
      }}
    />
  );
};
