import { createAsyncThunk } from '@reduxjs/toolkit';
import { OrderHistoryDetails } from 'api/orders/getOrderInfoById/Models';
import { getOrderInfoById } from 'api/orders/getOrderInfoById';

type AsyncThunkOptions = {
  rejectValue: number;
};

export const fetchOrderInfoById = createAsyncThunk<
  OrderHistoryDetails,
  string | number,
  AsyncThunkOptions
>('orders/fetchOrderInfoById', async (orderId, { rejectWithValue }) => {
  try {
    return await getOrderInfoById(orderId);
  } catch (error) {
    return rejectWithValue(error.response.status);
  }
});
