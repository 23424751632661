import { apiClient } from 'config/auth';
import { ENDPOINTS } from 'api/endpoints';
import { Arguments, CatalogDetailsResponse } from './Models';

export const getCatalogInfoById = async (
  catalogId: number,
  params: Arguments,
): Promise<CatalogDetailsResponse> => {
  const response = await apiClient.get(
    `${ENDPOINTS.CLIENT_CATALOGS}/brands/${catalogId}`,
    {
      params,
    },
  );

  return response.data;
};
