import { createReducer } from '@reduxjs/toolkit';
import { setOriginRoutePath } from 'store/reactRouterHistory/actions';

type HistoryState = {
  originPathName: string;
};

const initialState: HistoryState = {
  originPathName: '',
};

export const reactRouterHistory = createReducer(initialState, (builder) => {
  builder.addCase(setOriginRoutePath, (state, action) => {
    state.originPathName = action.payload;
  });
});
