import { apiClient } from 'config/auth';
import { ENDPOINTS } from 'api/endpoints';
import { Params } from './Models';
import { Options } from 'entries/filters';

export const getFilterSuppliers = async (params?: Params): Promise<Options> => {
  const response = await apiClient.get(ENDPOINTS.FILTER_SUPPLIERS, { params });

  return response.data;
};
