import { createReducer } from '@reduxjs/toolkit';
import { Breadcrumb } from 'store/breadcrumbs/Models';
import { clearBreadcrumbs, pushBreadcrumb } from 'store/breadcrumbs/actions';

export const initialState: Breadcrumb[] = [];

export const breadcrumbs = createReducer(initialState, (builder) => {
  builder
    .addCase(pushBreadcrumb, (state, action) => {
      return action.payload;
    })
    .addCase(clearBreadcrumbs, () => {
      return initialState;
    });
});
