export const RESPONSE_STATUS_400 = 400;
export const RESPONSE_STATUS_401 = 401;
export const RESPONSE_STATUS_403 = 403;
export const RESPONSE_STATUS_404 = 404;
export const RESPONSE_STATUS_406 = 406;
export const RESPONSE_STATUS_419 = 419;
export const RESPONSE_STATUS_500 = 500;
export const RESPONSE_STATUS_503 = 503;

export const RESPONSE_NOT_FOUND = 'Not Found';
export const CATALOG_ASSIGNED_TO_CLIENT = 'CatalogAssignedToClient';
export const localStoragePreviousPagePath = 'previousPagePath';

export const imgSizes = {
  small: '64',
  medium: '128',
  large: '512',
};
