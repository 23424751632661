import { XoXoRepository } from './xoXoRepositoryInterface';

const XoXoKey = 'isXoXoValid';
const XoXoLinkKey = 'isXoXoRedirectLink';

export const createXoXoRepository = (): XoXoRepository => {
  return {
    getIsXoXoNeedToCreate() {
      return JSON.parse(localStorage.getItem(XoXoKey) || 'false');
    },
    setIsXoXoNeedToCreate(isNeedToUpdate: boolean) {
      return localStorage.setItem(XoXoKey, JSON.stringify(isNeedToUpdate));
    },
    removeXoXo() {
      return localStorage.removeItem(XoXoKey);
    },

    getRedirectLink() {
      return localStorage.getItem(XoXoLinkKey) || '';
    },
    setRedirectLink(link: string) {
      return localStorage.setItem(XoXoLinkKey, link);
    },
    removeRedirectLink() {
      return localStorage.removeItem(XoXoLinkKey);
    },
  };
};
