import { USER_PERMISSIONS, USER_ROLES } from './index';
import { PERMISSIONS } from './userPermissions';
import { ROLES } from './userRoles';

export enum userRole {
  Admin = 1,
  User,
  AccountingManager,
  CustomerService,
  Sales,
}

const {
  INDEX,
  ORDERS_LANDING,
  ORDER_INFORMATION_LANDING,
  SIGN_IN_LANDING,
  SHOPPING_CARTS_LANDING,
  SHOPPING_CARTS_INFORMATION,
  CATALOG_LANDING,
  CATALOG_INFORMATION,
  PAGE_NOT_FOUND,
  RESET_PASSWORD_LANDING,
  CREATE_NEW_PASSWORD_LANDING,
  RESET_PASSWORD_LINK_EXPIRED_LANDING,
  INVOICES,
  FUNDINGS,
  USER_INFORMATION,
  MAINTENANCE,
  CLIENT_HOME,
  MY_COMPANY,
  FINANCE_OPERATION,
} = PERMISSIONS;

const { USER } = ROLES;

export const RULES: {
  [key in USER_ROLES]: {
    static: USER_PERMISSIONS[];
    dynamic?: USER_PERMISSIONS[];
  };
} = {
  [USER]: {
    static: [
      INDEX,
      SIGN_IN_LANDING,
      USER_INFORMATION,
      CATALOG_LANDING,
      CATALOG_INFORMATION,
      PAGE_NOT_FOUND,
      RESET_PASSWORD_LANDING,
      CREATE_NEW_PASSWORD_LANDING,
      RESET_PASSWORD_LINK_EXPIRED_LANDING,
      ORDERS_LANDING,
      SHOPPING_CARTS_LANDING,
      SHOPPING_CARTS_INFORMATION,
      ORDER_INFORMATION_LANDING,
      MAINTENANCE,
      CLIENT_HOME,
      MY_COMPANY,
      FUNDINGS,
      INVOICES,
      FINANCE_OPERATION,
    ],
  },
};
