export enum CatalogClientsFilter {
  Search = 'search',
  SettlementMethod = 'settlementMethod',
  AccountStatus = 'accountStatus',
}

export interface CatalogClientsFilterInputs {
  [CatalogClientsFilter.Search]: string;
  [CatalogClientsFilter.AccountStatus]: string;
  [CatalogClientsFilter.SettlementMethod]: string;
}
