import { apiClient } from 'config/auth';
import { ENDPOINTS } from 'api/endpoints';
import { ShoppingCart as Response } from './Models';

export const getShoppingCartById = async (
  id: string | number,
): Promise<Response> => {
  const response = await apiClient.get(`${ENDPOINTS.SHOPPING_CART_LIST}/${id}`);

  return response.data;
};
