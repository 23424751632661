import React from 'react';
import { Typography } from '@material-ui/core';
import ErrorRoundedIcon from '@material-ui/icons/ErrorRounded';
import CheckCircleOutlineRoundedIcon from '@material-ui/icons/CheckCircleOutlineRounded';
import { ToastContainer, ToastContainerProps } from 'react-toastify';

import { styledComponentTheme } from 'modules/theme/styledComponentTheme';

export const toastProps: Partial<ToastContainerProps> = {
  autoClose: 3000,
  position: 'bottom-center',
  closeButton: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  pauseOnFocusLoss: false,
  newestOnTop: true,
  limit: 3,
};

type Mode = 'success' | 'warning' | 'error';

const resolveIcon = (type: Mode) => {
  switch (type) {
    case 'error':
    case 'warning':
      return <ErrorRoundedIcon />;
    case 'success':
      return <CheckCircleOutlineRoundedIcon />;
    default:
      break;
  }
};

export const simpleToast = (description: string, type: Mode) => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        width: 'auto',
      }}
    >
      {resolveIcon(type)}
      <Typography
        variant="subtitle2"
        style={{
          marginLeft: '10px',
          fontWeight: styledComponentTheme.fontWeights.regular,
        }}
      >
        {description}
      </Typography>
    </div>
  );
};

export { ToastContainer };
