import { ClientsFilterField } from 'entries/clientsList';
import { ClientsState } from './Models';
import { createReducer } from '@reduxjs/toolkit';
import {
  addClientAccount,
  fetchClientById,
  fetchClients,
  resetActiveClientError,
  setClientsSearch,
  updateClient,
  changeStatus,
  updateClientAccount,
} from 'store/clients/actions';

export const initialState: ClientsState = {
  clients: {
    pageSize: 0,
    pageIndex: 0,
    count: 0,
    items: [],
  },
  filter: {
    [ClientsFilterField.Search]: '',
    [ClientsFilterField.AccountType]: '',
    [ClientsFilterField.AccountStatus]: '',
  },
  activeClient: null,
  isLoading: false,
  errors: {
    clientList: null,
    activeClient: null,
  },
};

export const clients = createReducer(initialState, (builder) => {
  builder
    .addCase(resetActiveClientError, (state) => {
      state.errors.activeClient = null;
    })
    .addCase(setClientsSearch, (state, action) => {
      state.filter = action.payload;
    })
    .addCase(updateClient, (state, action) => {
      state.activeClient = action.payload;
    })
    .addCase(updateClientAccount, (state, action) => {
      if (state.activeClient) {
        state.activeClient.accounts = state.activeClient?.accounts?.map(
          (account) =>
            account.id === action.payload.id ? action.payload : account,
        );
      }
    })
    .addCase(addClientAccount, (state, action) => {
      if (state.activeClient) {
        state.activeClient.accounts = [
          ...state.activeClient?.accounts,
          action.payload,
        ];
      }
    })
    .addCase(fetchClients.pending, (state, action) => {
      state.isLoading = true;
      state.errors.clientList = null;
    })
    .addCase(fetchClients.fulfilled, (state, action) => {
      state.isLoading = false;
      state.clients = action.payload;
    })
    .addCase(fetchClients.rejected, (state, action) => {
      state.isLoading = false;
      state.errors.clientList = action.payload || 500;
    })
    .addCase(changeStatus, (state, action) => {
      if (state.activeClient) {
        if (state.activeClient.status === 'Live') {
          state.activeClient.status = 'Inactive';
        } else {
          state.activeClient.status = 'Live';
        }
      }
    })
    .addCase(fetchClientById.pending, (state, action) => {
      state.isLoading = true;
      state.errors.activeClient = null;
    })
    .addCase(fetchClientById.fulfilled, (state, action) => {
      state.isLoading = false;
      state.activeClient = action.payload;
    })
    .addCase(fetchClientById.rejected, (state, action) => {
      state.isLoading = false;
      state.errors.activeClient = action.payload || 500;
    });
});
