import { ENDPOINTS } from 'api/endpoints';
import { apiClient } from 'config/auth';
import { Arguments, CompanyUsersResponse } from './Models';

export const getCompanyUsers = async (
  params: Arguments,
): Promise<CompanyUsersResponse> => {
  const response = await apiClient.get(`${ENDPOINTS.PROFILE}/users-info`, {
    params,
  });
  return response.data;
};
