import { createAction } from '@reduxjs/toolkit';

export const setResetPasswordEmailAndCode = createAction<{
  email: string;
  code: string;
}>('resetPassword/setResetPasswordEmailAndCode');

export const successfulResetPasswordRequest = createAction(
  'resetPassword/successfulResetPasswordRequest',
);
