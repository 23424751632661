import { combineReducers } from 'redux';
import { filter } from 'store/filters/reducer';
import { clients } from 'store/clients/reducer';
import { catalogs } from 'store/catalogs/reducer';
import { order } from 'store/order/reducer';
import { ordersHistory } from 'store/ordersHistory/reducer';
import { ordersHistoryClient } from 'store/ordersHistoryClient/reducer';
import { resetPassword } from 'store/resetPassword/reducer';
import { currentPageName } from 'store/currentPageName/reducer';
import { reactRouterHistory } from 'store/reactRouterHistory/reducer';
import { breadcrumbs } from 'store/breadcrumbs/reducer';
import { shoppingCarts } from 'store/shoppingCart/reducer';
import { users } from 'store/users/reducer';
import { myCompany } from 'store/myCompany/reducer';
import { notifications } from 'store/notifications/reducer';

export const rootReducer = combineReducers({
  clients,
  filter,
  catalogs,
  order,
  ordersHistory,
  ordersHistoryClient,
  resetPassword,
  currentPageName,
  reactRouterHistory,
  breadcrumbs,
  shoppingCarts,
  users,
  myCompany,
  notifications,
});

export type RootState = ReturnType<typeof rootReducer>;
