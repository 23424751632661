export default {
  primary: '#4e2787',
  primaryLight: '#ac99c7',
  primaryDark: '#301754',
  warningLight: '#ffB547',
  warningMain: '#ff9800',
  warningDark: '#c77700',
  successLight: '#7bc67e',
  successDark: '#3b873e',
  successMain: '#4caf50',
  errorLight: '#f88078',
  errorDark: '#e31b0c',
  errorMain: '#e91e63',
  grey300: '#e0e0e0',
  grey50: '#fafafa',
  greyColor: '#7b7c9a',
  disabledInputColor: 'rgba(0, 0, 0, 0.70)',
  lightBlack: 'rgba(0, 0, 0, 0.87)',
  background: '#f4f4f7',
  textColor: '#1f224d',
  headerBg: '#f8f8f8',
  darkBlueBg: '#270F48',
  lightBlueBg: '#301754',
  sidebarText: '#9fa0b1',
  sidebarSubText: '#7b7c9a',
  activeLink: '#494fb3',
  activeLinkSidebar: '#fff',
  solidWhite: '#fff',
  sidebarActiveIcon: '#6563ff',
  cardBg: '#e9ebf1',
  imgPlaceholder: '#ceced8',
  lightViolet: '#34355c',
  redBasic: '#ff0000',
  dark: '#212121',
  brightGreen: '#4caf50',
  gray: '#747474',
  lightGrey: '#c7c7c7',
  tableHeaderCell: 'rgba(0, 0, 0, 0.54)',
  dropzoneSubtitle: 'rgba(0, 0, 0, 0.54)',
  uploadedFilesTitle: 'rgba(0, 0, 0, 0.54)',
  emptyCartTitle: 'rgba(0, 0, 0, 0.54)',
  denominationBg: '#f1f2f9',
  black: '#000000',
  preloaderOpacity: 'rgba(255, 255, 255, 0.7)',
  scrollBar: '#d3d3d3',
  actionsIcon: '#747474',
  blueBorder: 'rgba(33, 150, 243, 0.5)',
  attachFileIcon: 'rgba(33, 150, 243, 0.5)',
  dropzoneActiveBg: 'rgba(33, 150, 243, 0.04)',
  extraLightBlack: 'rgba(0, 0, 0, 0.38)',
  darkGreen: 'rgba(49, 138, 17, 1)',
  lightGreen: 'rgba(237, 252, 232, 1)',
  lightRed: 'rgba(252, 237, 232, 1)',
  darkRed: 'rgba(213, 60, 12, 1)',
  lightYellow: 'rgba(253, 250, 226, 1)',
  darkYellow: 'rgba(179, 141, 5, 1)',
  lightBlue: 'rgba(236, 248, 252, 1)',
  darkBlue: 'rgba(45, 138, 167, 1)',
  lightPurple: 'rgba(243, 236, 252, 1)',
  darkPurple: 'rgba(95, 37, 177, 1)',
  lightGreyBj: 'rgba(245, 245, 245, 1)',
  darkGrey: 'rgba(159, 160, 177, 1)',
  navActiveBg: '#39166A',
  navActiveBorder: '#9A7CC9',
};