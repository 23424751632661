import React from 'react';
import clsx from 'clsx';
import logo from 'modules/assets/gif/load.gif';

import { useClasses } from './style';

type Props = {
  wrapClass?: string;
  loaderClass?: string;
};

export const Preloader = ({ wrapClass, loaderClass }: Props) => {
  const classes = useClasses();

  return (
    <div className={clsx(wrapClass && wrapClass, classes.preloaderWrap)}>
      <img
        src={logo}
        alt="loader_img"
        className={clsx(loaderClass && loaderClass, classes.preloader)}
      />
    </div>
  );
};

export const PreloaderForTable = () => {
  const classes = useClasses();

  return (
    <div className={classes.preloaderWrapForTable}>
      <img src={logo} alt="loader_img" className={classes.preloader} />
    </div>
  );
};
