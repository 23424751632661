import { OrderItemResponse } from 'api/orders/getOrderItems/Models';
import { getOrderItems } from 'api/orders/getOrderItems';
import { createAsyncThunk } from '@reduxjs/toolkit';

type Params = {
  pageSize: number;
  pageIndex: number;
};

type AsyncThunkOptions = {
  rejectValue: number;
};

export const fetchOrderItems = createAsyncThunk<
  OrderItemResponse,
  { id: string } & { params: Params },
  AsyncThunkOptions
>('orders/fetchOrderItems', async ({ id, params }, { rejectWithValue }) => {
  try {
    return await getOrderItems(id, params);
  } catch (error) {
    return rejectWithValue(error.response.status);
  }
});
