import { apiClient } from 'config/auth';
import { ENDPOINTS } from 'api/endpoints';
import { Arguments, Response } from './Models';

export const addProductsToCart = async (
  basketId: number,
  clientId: string | number,
  accountId: string | number,
  body: Arguments,
): Promise<Response> => {
  const params = {
    clientId,
    accountId,
  };
  const response = await apiClient.post(
    `${ENDPOINTS.SHOPPING_CART_LIST}/${basketId}/products`,
    body,
    { params },
  );

  return response.data;
};
