export enum CatalogProductsFilterField {
  Search = 'searchText',
  SuppliersId = 'supplierId',
  Country = 'country',
  Currency = 'currency',
  Brand = 'brandId',
  Denomination = 'denomination',
}

export interface CatalogProductsFilterInputs {
  [CatalogProductsFilterField.Search]: string;
  [CatalogProductsFilterField.SuppliersId]: string;
  [CatalogProductsFilterField.Country]: string;
  [CatalogProductsFilterField.Currency]: string;
  [CatalogProductsFilterField.Brand]: string;
  [CatalogProductsFilterField.Denomination]: string;
}
