export enum ClientsFilterField {
  Search = 'search',
  AccountType = 'accountType',
  AccountStatus = 'accountStatus',
}

export interface FilterInputs {
  [ClientsFilterField.Search]: string;
  [ClientsFilterField.AccountType]: string;
  [ClientsFilterField.AccountStatus]: string;
}
