import { createAsyncThunk } from '@reduxjs/toolkit';
import { getCompanyDetails } from 'api/myCompany/getCompanyDetails';
import { MyCompanyDetailsResponse } from 'api/myCompany/getCompanyDetails/Models';

type AsyncThunkOptions = {
  rejectValue: number;
};

export const fetchCompanyDetails = createAsyncThunk<
  MyCompanyDetailsResponse,
  void,
  AsyncThunkOptions
>('orders/fetchCompanyDetails', async (_, { rejectWithValue }) => {
  try {
    return await getCompanyDetails();
  } catch (error) {
    return rejectWithValue(error.response.status);
  }
});
