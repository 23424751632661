import { UserSettingsRepository } from './userSettingsRepositoryInterface';

const userKey = 'userSettings';

export const createUserSettingsRepository = (): UserSettingsRepository => {
  return {
    getUserSettings() {
      const userSettings = localStorage.getItem(userKey);

      return typeof userSettings === 'string' ? JSON.parse(userSettings) : null;
    },

    setUserSettings(user) {
      localStorage.setItem(userKey, JSON.stringify(user));
    },

    removeUserSetting() {
      localStorage.removeItem(userKey);
    },
  };
};
