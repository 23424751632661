import { apiClient } from 'config/auth';
import { ENDPOINTS } from 'api/endpoints';
import { OrderHistoryDetails } from './Models';

export const getOrderInfoById = async (
  orderId: string | number,
): Promise<OrderHistoryDetails> => {
  const response = await apiClient.get(`${ENDPOINTS.ORDERS_CLIENT}/${orderId}`);

  return response.data;
};
