import { apiClient } from 'config/auth';
import { ENDPOINTS } from 'api/endpoints';
import { Arguments, OrderHistoryModel } from './Models';

export const getOrderHistory = async (
  params: Arguments,
): Promise<OrderHistoryModel> => {
  const response = await apiClient.get(ENDPOINTS.ORDERS_HISTORY, { params });

  return response.data;
};
