import React from 'react';

import GlobalStyle from 'modules/theme/GlobalStyle';
import materialTheme from 'modules/theme/materialTheme';
import { styledComponentTheme } from 'modules/theme/styledComponentTheme';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';
import { ThemeProvider } from '@material-ui/core/styles';
import { Props } from './types';

const ThemeProviderWrapper = ({ children }: Props): React.ReactElement => (
  <ThemeProvider theme={materialTheme}>
    <StyledThemeProvider theme={styledComponentTheme}>
      <GlobalStyle />
      {children}
    </StyledThemeProvider>
  </ThemeProvider>
);

export default ThemeProviderWrapper;
