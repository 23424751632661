export const routes = {
  index: '/',
  brandsLanding: '/',
  signInLanding: '/signin',
  ordersLanding: '/orders',
  catalogsLanding: '/catalogs',
  catalogInformation: '/catalogs/:catalogId',
  orderInformationLanding: '/orders/:orderId',
  // shoppingCartsLanding: '/shopping-carts',
  // shoppingCartsInformation: '/shopping-carts/:shoppingCartId',
  invoices: '/invoices',
  usersInformation: '/my-profile',
  pageNotFound: '/page-not-found',
  maintenance: '/maintenance',
  myCompany: '/my-company',
  myCompanyDetails: '/my-company/details',
  myCompanyUsers: '/my-company/users',
  myCompanyNotifications: '/my-company/notifications',
  myCompanyAPISettings: '/my-company/api-settings',
  funding: '/funding',
  accounts: '/accounts',
  accountHistory: '/accounts/:accountId',
  notifications: '/notifications',
  ticket: '/ticket-management',
  cart: '/cart',
  resetPasswordLanding: '/reset-password',
  createNewPasswordLanding: '/create-new-password',
  resetPasswordLinkExpiredLanding: '/reset-password/link-expired',
  financeOperation: '/finance-operation',
};
