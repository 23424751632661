export enum CatalogsListFilterField {
  CatalogId = 'catalogId',
  Country = 'country',
  Currency = 'currency',
  SearchText = 'searchText',
  CategoryId = 'categoryId',
}

export interface CatalogsListFilterInputs {
  [CatalogsListFilterField.CatalogId]: string;
  [CatalogsListFilterField.Country]: string;
  [CatalogsListFilterField.Currency]: string;
  [CatalogsListFilterField.SearchText]: string;
  [CatalogsListFilterField.CategoryId]: string;
}
