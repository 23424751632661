export enum ShoppingCartsListFilterField {
  CurrencyCode = 'currencyCode',
  ClientId = 'clientId',
  BrandName = 'brandName',
}

export interface FilterInputs {
  [ShoppingCartsListFilterField.CurrencyCode]: string;
  [ShoppingCartsListFilterField.ClientId]: string;
  [ShoppingCartsListFilterField.BrandName]: string;
}
