import React, { Suspense } from 'react';
import ThemeProviderWrapper from 'modules/wrappers/ThemeProvider';
import { PERMISSIONS } from 'config/RBAC/userPermissions';
import { xoXoRepository } from 'config/auth';
import { Preloader } from 'modules/ui/atoms/Preloader';
import { PrivateRoute } from 'modules/ui/atoms/PrivateRoute';
import { Route, Switch } from 'react-router-dom';
import { routes } from 'routes/routes';

import ToastContainer, { toastProps } from 'modules/ui/atoms/ToastContainer';
import Maintenance from '../../../Maintenance';

const SignIn = React.lazy(() => import('modules/SignIn'));
const XoXo = React.lazy(() => import('modules/XoXo'));
const Layout = React.lazy(() => import('modules/ui/molecules/Layout'));
const ResetPassword = React.lazy(() => import('modules/ResetPassword'));
const CreateNewPassword = React.lazy(() => import('modules/CreateNewPassword'));
const ResetPasswordLinkExpired = React.lazy(() =>
  import('modules/ResetPasswordLinkExpired'),
);

const AppContainer = () => {
  const isXoXoNeedToCreate = xoXoRepository.getIsXoXoNeedToCreate();

  return (
    <Suspense fallback={<Preloader />}>
      <ThemeProviderWrapper>
        <>
          <Switch>
            <PrivateRoute
              access="unauthorized"
              path={routes.signInLanding}
              permission={PERMISSIONS.SIGN_IN_LANDING}
              exact
              component={SignIn}
            />
            <PrivateRoute
              access="authorized"
              path={routes.maintenance}
              permission={PERMISSIONS.MAINTENANCE}
              exact
              component={Maintenance}
            />
            <PrivateRoute
              access="unauthorized"
              path={routes.resetPasswordLinkExpiredLanding}
              permission={PERMISSIONS.RESET_PASSWORD_LINK_EXPIRED_LANDING}
              exact
              component={ResetPasswordLinkExpired}
            />
            <PrivateRoute
              access="unauthorized"
              path={routes.createNewPasswordLanding}
              permission={PERMISSIONS.CREATE_NEW_PASSWORD_LANDING}
              exact
              component={CreateNewPassword}
            />
            <PrivateRoute
              access="unauthorized"
              path={routes.resetPasswordLanding}
              permission={PERMISSIONS.RESET_PASSWORD_LANDING}
              exact
              component={ResetPassword}
            />
            <Route path={routes.index} name="Home" component={Layout} />
          </Switch>
          <ToastContainer {...toastProps} />
        </>
      </ThemeProviderWrapper>
    </Suspense>
  );
};

export default AppContainer;
