export const ENDPOINTS = {
  ORDERS: '/orders',
  ORDERS_HISTORY: '/orders/history',
  ORDERS_CLIENT: '/orders/client',
  SIGN_IN: '/auth/login',
  CHANGE_USER_PASSWORD: '/auth/change-password',
  RESET_PASSWORD: '/auth/reset-forgotten-password',
  CONFIRM_NEW_PASSWORD: '/auth/confirm-forgotten-password',
  CATALOGS: '/catalogs',
  CLIENTS: '/clients',
  FILTER_COUNTRIES: '/filters/countries',
  FILTER_CURRENCIES: '/filters/currencies',
  FILTER_ACCOUNT_CURRENCIES: '/filters/account-currencies',
  FILTER_SUPPLIERS: '/filters/suppliers',
  FILTER_NON_INTGR_SUPPLIERS: '/filters/non-integrated-suppliers',
  FILTER_BRANDS: '/filters/brands',
  FILTER_CLIENTS: '/filters/clients',
  FILTER_INTEGRATION_TYPE: '/filters/integration-types',
  FILTER_COMMERCIAL_STRUCTURE: '/filters/commercial-structures',
  FILTER_STATUSES: '/filters/statuses',
  FILTER_PAYMENT_METHODS: '/filters/settlement-methods',
  FILTER_DISCOUNT_TYPES: '/filters/discount-types',
  FILTER_ACCOUNT_TYPE: '/filters/account-types',
  USER_ROLES: '/filters/user-roles',
  SHOPPING_CART_LIST: '/baskets',
  ORDER_CHECKOUT: '/baskets/checkout',
  ORDER_VALIDATION: '/baskets/validate-order',
  TRANSACTION_REPORT: '/transaction-report',
  ACCOUNT_HISTORY: '/transactions',
  XO_XO_SUPPLIER: '/supplier-integration/xoxo',
  INVOICES: '/invoices',
  FUNDING: '/funding-requests',
  FUNDING_CLIENTS: '/funding-requests/client',
  USERS: '/users',
  USER_REGISTER: '/registration/register',
  EMAIL_CHECK: '/registration/is-email-free',
  PROFILE: '/profile',
  CLIENTINVOICE: '/ClientPortalInvoices',
  NOTIFICATIONS: '/notifications',
  CLIENT_CATALOGS: '/clientcatalogs',
  FILTER_CATEGORIES: '/filters/categories',
  CLIENTPORTALREPORT: '/ClientPortalReport',
  SHOPPING_CART: '/basket',
  CLIENT_PORTAL_ORDERS: '/ClientPortalOrders/client',
  AUTH: '/auth',
  CLIENT_SETTINGS: '/clientSetting',
};
