import { CatalogsListFilterInputs } from 'entries/catalogs';
import { getCatalogs } from 'api/catalogs/getCatalogs';
import { Arguments, Response } from 'api/catalogs/getCatalogs/Models';
import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

type AsyncThunkOptions = {
  rejectValue: number;
};

export const setCatalogSearch = createAction<Partial<CatalogsListFilterInputs>>(
  'catalogs/setCatalogSearch',
);

export const clearCatalogsFilters = createAction(
  'catalogs/clearCatalogsFilters',
);

export const fetchCatalogs = createAsyncThunk<
  Response,
  Arguments,
  AsyncThunkOptions
>('catalogs/fetchCatalogs', async (queryParams, { rejectWithValue }) => {
  try {
    return await getCatalogs(queryParams);
  } catch (error) {
    return rejectWithValue(error.response.status);
  }
});
