import { createReducer } from '@reduxjs/toolkit';
import {
  fetchOrdersHistoryClient,
  saveOrdersHistoryClientFilterStatus,
} from 'store/ordersHistoryClient/actions';

import { OrdersHistoryState } from './Models';

export const initialState: OrdersHistoryState = {
  ordersHistory: {
    pageSize: 0,
    pageIndex: 0,
    count: 0,
    items: [],
  },
  isLoading: true,
  isFilterActive: false,
  error: null,
};

export const ordersHistoryClient = createReducer(initialState, (builder) => {
  builder
    .addCase(fetchOrdersHistoryClient.pending, (state) => {
      state.isLoading = true;
      state.error = null;
    })
    .addCase(fetchOrdersHistoryClient.fulfilled, (state, action) => {
      state.isLoading = false;
      state.ordersHistory = action.payload;
    })
    .addCase(fetchOrdersHistoryClient.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload || 500;
    })
    .addCase(saveOrdersHistoryClientFilterStatus, (state, action) => {
      state.isFilterActive = action.payload;
    });
});
