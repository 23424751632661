import { createMuiTheme, createStyles } from '@material-ui/core/styles';
import { styledComponentTheme } from 'modules/theme/styledComponentTheme';

export default createMuiTheme({
  palette: {
    primary: {
      light: styledComponentTheme.colors.primaryLight,
      main: styledComponentTheme.colors.primary,
      dark: styledComponentTheme.colors.primaryDark,
    },
    secondary: {
      main: styledComponentTheme.colors.errorMain,
    },
    text: {
      primary: styledComponentTheme.colors.textColor,
    },
  },
  typography: {
    fontFamily: styledComponentTheme.fontFamily,
    h1: {
      fontWeight: styledComponentTheme.fontWeights.medium,
      textTransform: 'capitalize',
    },
    h5: {
      marginBottom: '20px',
    },
  },
  overrides: {
    MuiTypography: createStyles({
      body2: {
        fontSize: 14,
      },
    }),
    MuiTabs: createStyles({
      indicator: {
        background: styledComponentTheme.colors.warningMain,
      },
    }),
    MuiFab: createStyles({
      root: {
        fontFamily: styledComponentTheme.fontFamily,
        textTransform: 'none',
      },
    }),
    MuiButton: createStyles({
      root: {
        fontFamily: styledComponentTheme.fontFamily,
        textTransform: 'none',
      },
      textSizeLarge: {
        padding: '8px 21px',
      },
      contained: {
        boxShadow: 'none',
      },
    }),
    MuiTooltip: createStyles({
      tooltip: {
        fontFamily: styledComponentTheme.fontFamily,
        fontWeight: styledComponentTheme.fontWeights.regular,
        color: styledComponentTheme.colors.lightBlack,
        borderRadius: '4px',
        letterSpacing: '0.15px',
        fontSize: '14px',
      },
    }),
    MuiTableCell: createStyles({
      head: {
        whiteSpace: 'nowrap',
        color: styledComponentTheme.colors.tableHeaderCell,
        fontSize: '12px!important',
        fontWeight: styledComponentTheme.fontWeights.regular,
      },
      stickyHeader: {
        position: 'static',
      },
      root: {
        fontSize: '14px!important',
        fontFamily: `${styledComponentTheme.fontFamily}!important`,
      },
    }),
    MuiInputBase: createStyles({
      root: {
        padding: 0,
        background: styledComponentTheme.colors.solidWhite,
      },
      input: {
        padding: '16px 12px 17px!important',
        '&::placeholder': {
          fontSize: 14,
        },
      },
    }),
    MuiOutlinedInput: createStyles({
      notchedOutline: {
        borderWidth: '1px!important',
      },
      inputMultiline: {
        padding: '0!important',
      },
      multiline: {
        padding: '19px 14px',
      },
    }),
    MuiInputLabel: createStyles({
      root: {
        fontSize: '14px',
        fontFamily: styledComponentTheme.fontFamily,
      },
      outlined: {
        transform: 'translate(15px, 20px) scale(1)',
      },
    }),
    MuiSelect: createStyles({
      root: {
        border: 'none',
        paddingRight: '24px!important',
      },
      select: {
        '&:focus': {
          backgroundColor: 'transparent',
        },
      },
      icon: {
        border: 'solid #8e8e8e',
        borderWidth: '0 2px 2px 0',
        display: 'inline-block',
        width: '2px',
        height: '2px',
        padding: '2px',
        margin: '6px 6px 0 0',
        transform: 'rotate(45deg)',
        color: styledComponentTheme.colors.solidWhite,
        transition: '.4s',
        '&.MuiSelect-iconOpen': {
          transform: 'rotate(225deg)',
        },
      },
    }),
    MuiDialogTitle: {
      root: {
        padding: '0',
        paddingBottom: '28px',
        minWidth: '200px',
      },
    },
    MuiDialogContent: {
      root: {
        padding: '0',
        paddingBottom: '20px',
      },
    },
    MuiDialogActions: {
      root: {
        padding: '0',
        paddingTop: '20px',
      },
    },
    MuiFormHelperText: createStyles({
      root: {
        marginLeft: '5px',
        position: 'absolute',
        top: '100%',
        fontSize: '0.7rem',
        zIndex: 1,
      },
      contained: {
        marginLeft: '5px',
        marginRight: '0',
      },
    }),
    MuiTab: createStyles({
      root: {
        fontFamily: styledComponentTheme.fontFamily,
      },
      wrapper: {
        flexDirection: 'row',
        color: styledComponentTheme.colors.dark,
        textTransform: 'none',
      },
    }),
    MuiFormControl: createStyles({
      marginNormal: {
        marginTop: '8px',
      },
    }),
    MuiTablePagination: createStyles({
      selectRoot: {
        marginRight: '15px',
      },
    }),
    MuiSwitch: createStyles({
      colorPrimary: {
        '&$checked': {
          color: styledComponentTheme.colors.warningMain,
        },
        '&$checked + $track': {
          backgroundColor: styledComponentTheme.colors.warningMain,
        },
      },
    }),
  },
  breakpoints: {
    values: {
      xs: 576,
      sm: 768,
      md: 992,
      lg: 1366,
      xl: 1600,
    },
  },
});
