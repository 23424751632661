import {
  Arguments,
  OrderHistoryClientModel,
} from 'api/orders/getOrderHistoryClient/Models';
import { getOrderHistoryClient } from 'api/orders/getOrderHistoryClient';
import { createAsyncThunk, createAction } from '@reduxjs/toolkit';

type AsyncThunkOptions = {
  rejectValue: number;
};

export const fetchOrdersHistoryClient = createAsyncThunk<
  OrderHistoryClientModel,
  Arguments,
  AsyncThunkOptions
>(
  'orderHistoryClient/fetchOrdersHistoryClient',
  async (queryParams, { rejectWithValue }) => {
    try {
      return await getOrderHistoryClient(queryParams);
    } catch (error) {
      return rejectWithValue(error.response.status);
    }
  },
);

export const saveOrdersHistoryClientFilterStatus = createAction<boolean>(
  'orderHistoryClient/saveOrdersHistoryClientFilterStatus',
);
