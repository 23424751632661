import React, { useEffect } from 'react';

import { routes } from '../../routes/routes';
import { useSelector } from 'react-redux';
import { shoppingCartsList } from '../../store/shoppingCart/selectors';
import { getShoppingCartsList } from '../../api/shoppingCart';
import MaintenanceForm from './MaintenanceForm';

const Maintenance = () => {
  const cartList = useSelector(shoppingCartsList);

  const data = async () => {
    return await getShoppingCartsList({ pageSize: 100, pageIndex: 0 });
  };

  useEffect(() => {
    let interval: number;
    let timer: number;

    // eslint-disable-next-line prefer-const
    interval = setInterval(() => {
      void data().then((r) => {
        if (r) {
          window.location.assign(routes.index);
        }
      });
    }, 7000);

    // eslint-disable-next-line prefer-const
    timer = setTimeout(() => {
      clearInterval(interval);
    }, 300000);

    return () => {
      clearInterval(interval);
      clearTimeout(timer);
    };
  }, [cartList]);

  return <MaintenanceForm />;
};

export default Maintenance;
