import { Arguments, Response as ClientState } from 'api/clients/Models';
import { Client, FilterInputs } from 'entries/clientsList';
import { Account } from 'entries/account';
import { getClientById } from 'api/clients/getClientById';
import { getClients } from 'api/clients/getClients';
import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

export const resetActiveClientError = createAction(
  'clients/resetActiveClientError',
);

export const setClientsSearch = createAction<FilterInputs>(
  'clients/setClientsSearch',
);

export const updateClient = createAction<Client>('clients/updateClient');

export const updateClientAccount = createAction<Account>(
  'clients/updateClientAccount',
);

export const addClientAccount = createAction<Account>(
  'clients/addClientAccount',
);

export const fetchClients = createAsyncThunk<
  ClientState,
  Arguments,
  { rejectValue: number }
>('clients/fetchClients', async (queryParams, { rejectWithValue }) => {
  try {
    return await getClients(queryParams);
  } catch (error) {
    return rejectWithValue(error.response.status);
  }
});

export const fetchClientById = createAsyncThunk<
  Client,
  string | number,
  { rejectValue: number }
>('clients/fetchClientById', async (clientId, { rejectWithValue }) => {
  try {
    return await getClientById(clientId);
  } catch (error) {
    return rejectWithValue(error.response.status);
  }
});

export const changeStatus = createAction('clients/changeStatus');
