import { createGlobalStyle } from 'styled-components';

// eslint-disable-next-line no-unexpected-multiline
export default createGlobalStyle<any>`
  *{
    box-sizing: border-box;
  }
  
  html,
  body {
    width: 100%;
    margin: 0;
    padding: 0;
    background-color: ${(props) => props.theme.colors.background};
    color: ${(props) => props.theme.colors.success};
    font-family: ${(props) => props.theme.fontFamily};
    box-sizing: border-box;
  }

  input::-ms-reveal,
  input::-ms-clear {
    display: none;
  }

  .simplebar-track.simplebar-vertical {
    width: 9px!important;
  }

  .simplebar-scrollbar:before{
    background-color: ${(props) => props.theme.colors.scrollBar}!important;
    opacity: 1!important;
  }
 
  .simplebar-hover:before{
    background-color: #d3d3d3;
  }

  .simplebar-placeholder{
    width: 100%!important;
  }

  .Toastify__toast-container {
    width: 414px;
    font-family: ${(props) => props.theme.fontFamily};
  }

  .Toastify__toast--success {
    background: ${(props) => props.theme.colors.brightGreen};
  }

  .Toastify__toast--error {
    background: ${(props) => props.theme.colors.errorDark};
  }

  .MuiTableRow-root.Mui-selected{
    color: ${(props) => props.theme.colors.textColor};
  }

  a {
    color: ${(props) => props.theme.colors.dark};
  }

  div::-webkit-scrollbar {
    width: 10px!important;
    height: 10px!important;
    border: none;
  }
  div::-webkit-scrollbar-thumb {
    background-color: ${(props) => props.theme.colors.scrollBar};
    border-radius: 7px;
  }
  .MuiPaginationItem-page.Mui-selected {
    pointer-events: none;
  }
  .react-tel-input .form-control:focus {
    border-color: ${(props) => props.theme.colors.primary}!important;
    box-shadow: none!important;
  }

  .react-tel-input .special-label {
    font-family: ${(props) => props.theme.fontFamily};
    font-size: 0.75em!important;
    left: 10px!important;
  }
  .react-tel-input:focus .special-label{
    color: ${(props) => props.theme.colors.primary}!important;
  }

  .MuiAutocomplete-popper {
    margin-bottom: 8px;
  }

  .Toastify__toast--warning {
    background: #f1c40f;
 }

 .MuiTableCell-head {
   padding: 0.75rem !important;
 }

 .MuiTableBody-root tr td:nth-child(n) {
    padding: 0.75rem !important;
    font-size: 0.875rem !important;
 }
 .MuiTableBody-root tr td a{
   padding: 0 !important;
 }
`;
